
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { getTableConfig, inputList } from './config'
import { usePagination } from '@/hooks/usePagination'
import { auditLogAPI } from './api'
import { toResult } from '@/utils/toResult'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'auditLog',
  components: { CSearchList, CTable },
  setup() {
    const data = reactive({
      inputList: inputList(),
      dataSource: getTableConfig(),
      formData: ref<any>({})
    })

    const getList = async() => {
      data.dataSource.loading = true
      const p = Object.assign(
        {},
        { ...data.formData },
        {
          currPage: data.dataSource.pagination.currentPage,
          pageSize: data.dataSource.pagination.pageSize,
          startTime: data.formData.Time instanceof Array ? dayjs(data.formData.Time[0]).format('YYYY-MM-DD 00:00:00') : '',
          endTime: data.formData.Time instanceof Array ? dayjs(data.formData.Time[1]).format('YYYY-MM-DD 23:59:59') : ''
        }
      )
      p.Time && delete p.Time
      const [err, res] = await toResult(auditLogAPI(p))
      data.dataSource.loading = false
      if (err) return false
      data.dataSource.data = res.list || []
      data.dataSource.pagination.total = res.total
    }

    const handleSearch = (params): void => {
      console.log(params)
      data.formData = Object.assign({}, params)
      getList()
    }

    const handleReset = (params: object): void => {
      data.formData = params
    }

    // c-table pagination method hook
    const { pageCurrentChange, pageSizeChange } = usePagination(
      data.dataSource.pagination,
      () => {
        getList()
      }
    )

    onMounted(() => {
      // 默认展示最近30天数据
      handleSearch({
        Time: [(new Date() as any) - 3600 * 1000 * 24 * 30, new Date()]
      })
    })
    return {
      ...toRefs(data),
      handleSearch,
      handleReset,
      pageCurrentChange,
      pageSizeChange
    }
  }
})
