import { enumToOption } from '@/utils'

// 应用名称
export enum applicationType {
    SaaS管理平台 = 1,
    SaaS运营平台,
    企业版小程序,
    车主版小程序,
    司机版小程序
}
//   响应状态名称
export enum responseStateMsg {
    成功 = 1,
    失败,
}

const applicationTypeOption = enumToOption(applicationType)
const responseStateMsgOption = enumToOption(responseStateMsg)

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    isIndex: false,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'applicationTypeName',
      label: '应用类型',
      width: '80'
    },
    {
      key: 'operator',
      label: '操作人',
      width: '80'
    },
    {
      key: 'operatorTypeName',
      label: '帐号类型',
      width: '80'
    },
    {
      key: 'companyName',
      label: '所属企业',
      width: '100',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'operatorTime',
      label: '操作时间',
      width: '120'
    },
    {
      key: 'ip',
      label: 'IP',
      width: '60'
    },
    {
      key: 'requestUri',
      label: '接口地址',
      width: '180'
    },
    {
      key: 'requestBody',
      label: '请求参数',
      width: '180'
    },
    {
      key: 'responseStateMsgName',
      label: '响应状态',
      width: '60'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
// 搜索配置
export const inputList = () => [
  {
    type: 'select',
    label: '应用类型',
    key: 'applicationType',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: applicationTypeOption
  }, {
    type: 'datePicker',
    label: '操作时间',
    key: 'Time',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 390,
    format: 'YYYY-MM-DD',
    value: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), new Date()],
    // 设置禁用状态，参数为当前日期，要求返回 Boolean
    // disabledDate: (time) => {
    //   return time.getTime() > Date.now()
    // },
    // 日期类型 year/month/date/dates/ week/datetime/datetimerange/ daterange/monthrange
    dateType: 'daterange',
    // 开启日期范围后生效
    unlinkPanels: false,
    rangeSeparator: '-',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    rules: {
      // 默认 date 开启范围 array
      type: 'array'
    }
  },
  {
    type: 'select',
    label: '响应状态',
    key: 'responseStateMsg',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: responseStateMsgOption
  }
]
